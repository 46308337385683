import { createGlobalStyle, css } from "styled-components";
import { transparentize } from "polished";
import { normalize } from "styled-normalize";
import { typography } from "./src/fonts/typography";
import { colors } from "./src/theme/theme";
import { underline } from "./src/components/design-system/Typography/headings/Headings";

export interface BodyOverflowProps {
  overflowHidden?: boolean;
}

export const BodyOverflow = createGlobalStyle<BodyOverflowProps>`
    body {
        overflow: ${(p) => (p.overflowHidden ? "hidden" : "auto")};
    }
`;

BodyOverflow.defaultProps = {
  overflowHidden: false,
};

const GlobalStyle = createGlobalStyle`
${normalize}
${typography}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: ${({ theme }) => theme.typography.bodyFontFamily}, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    font-weight: 300;
    color: ${({ theme }) =>
      theme.theme.page === "dark" ? colors.white : colors.richBlack};
    background-color: ${({ theme }) =>
      theme.theme.page === "dark" ? colors.richBlack : colors.white};
}

body * {
    box-sizing: border-box;
}

::selection {
    background: ${({ theme }) => transparentize(0.9, theme.colors.magenta)};
    color: ${({ theme }) => theme.colors.mauve};
}

.dark-background *::selection {
    background: ${({ theme }) => transparentize(0.6, theme.colors.magenta)};
    color: ${({ theme }) => theme.colors.white};
}

.dark-background {
    .risk-warning {
        color: ${({ theme }) => theme.colors.white};
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: ${({ theme }) => theme.typography.headingFontFamily}, serif;
    font-weight: 300;
    color: ${({ theme }) =>
      theme.theme.page === "dark" ? colors.lightPurple : colors.richBlue};
    word-wrap: break-word;
    
    em {
        word-wrap: initial;
    }

    strong {
        font-weight: 400;
    }
}

h1 {
    margin: 0 0 0.75rem 0;
    font-size: 2.5rem;
    line-height: 3.125rem;

    ${({ theme }) => theme.breakpoints.up("md")} {
        font-size: 2.75rem;
        line-height: 3.375rem;
    }

    .xl-heading {
        padding: 0 0 1.5rem 0;
        margin: 0 0 1.5rem 0;
        font-size: 3rem;
        line-height: 3.625rem;
        
        ${({ theme }) => theme.breakpoints.up("md")} {
            font-size: 3.25rem;
            line-height: 3.875rem;
        }
    }

    &.xl-heading {
        padding: 0 0 1.5rem 0;
        margin: 0 0 1.5rem 0;
        font-size: 3rem;
        line-height: 3.625rem;
        
        ${({ theme }) => theme.breakpoints.up("md")} {
            font-size: 3.25rem;
            line-height: 3.875rem;
        }

        ${underline}
    }
    &.large-heading {
        padding: 0 0 1.5rem 0;
        margin: 0 0 1.5rem 0;
        font-size: 2.75rem;
        line-height: 3.375rem;
        
        ${({ theme }) => theme.breakpoints.up("md")} {
            font-size: 3rem;
            line-height: 3.625rem;
        }

        ${underline}
    }
}

h2 {
    margin: 0.5rem 0;
    font-size: 2.25rem;
    line-height: 2.875rem;
    ${({ theme }) => theme.breakpoints.up("md")} {
        font-size: 2.5rem;
        line-height: 3.215rem;
    }
}

h3 {
    margin: 0.5rem 0;
    font-size: 2rem;
    line-height: 2.75rem;
}

h4 {
    margin: 0.5rem 0;
    font-size: 1.75rem;
    line-height: 2.375rem;
}

h5 {
    margin: 0.5rem 0;
    font-size: 1.5rem;
    line-height: 2.125rem;
}

h6 {
    margin: 0.5rem 0;
    font-size: 1.25rem;
    line-height: 1.875rem;
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    
    &.small {
        font-size: 0.625rem;
        line-height: 1.25rem;

        ${({ theme }) => theme.breakpoints.up("md")} {
            font-size: 0.875rem;
            line-height: 1.375rem;
        }
    }

    &.xSmall {
        font-size: 0.625rem;
        line-height: 1.25rem;

        ${({ theme }) => theme.breakpoints.up("md")} {
            font-size: 0.75rem;
        }
    }

    &.large {
        font-size: 1.25rem;
    }

    &.quote-small {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }

    &.quote {
        font-size: 1.25rem;
        font-style: italic;
        font-weight: 300;
        line-height: 2rem;
    }

    &.quote-large {
        font-size: 1.85rem;
        font-style: italic;
        font-weight: 300;
        line-height: 2.5rem;
    }

    &.risk-warning {
        font-style: italic;
        font-family: ${({ theme }) =>
          theme.typography.headingFontFamily}, serif;
        color: ${({ theme }) =>
          theme.theme.page === "dark" ? colors.white : colors.richBlue};  
    }

    img {
        width: 100%;
    }

    a>img {
        width: auto;
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
        &.small {
            font-size: 0.875rem;
        }
    }
}

p,
i,
li,
span {
    strong {
        font-weight: 500; //this set the bold to use a heavier font weight
    }
}

p,
i, 
b, 
strong, 
li p {

    a, span.fake-link {
        position: relative;
        text-decoration: none;
        color: ${colors.magenta};
        cursor: pointer;
        white-space: nowrap;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: ${transparentize(0.75, colors.magenta)};
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform: rotateY(90deg);
            background-color: ${colors.magenta};
            transition: all 0.4s ease-in-out;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: ${colors.magenta};
            &:after {
            transform: rotateY(0deg);
            }
        }
    }
}

.align-center {
    ul {
        display: table;
        margin: 0 auto 1rem auto;
        li {
            text-align: left;
        }
    }
}

/**
 * Prismic label styles:
 * 
 * We can add a custom styles to the prismic editor by adding 'labels' - these allow the user to 
 * add a class to the element. We can then use this class to style the element.
 */

// TODO: The body large now is bigger than the body medium, this can be confusing for the users
// Tom is investigating if the body large and medium is used anywhere else and if we can merge this
.body-large {
    font-size: 1.06rem;
}

.body-large-light {
    font-size: 1.06rem;
    font-weight: 300;
}

.copy-break-words {
    word-wrap: break-word;
}

.long-link a {
    word-wrap: break-word;
    white-space: normal;
    text-decoration: underline;
    &:before, &:after {
        height: 0;
    }
}

/**
 * Banner specific styles:
 * 
 */
body.banner--open {

${({ theme }) => theme.breakpoints.up("md")} {
  .app--header {
    transition: transform 0.25s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    transform: translateY(50px);
  }
  .app--header-shim {
    height: 8.125rem;
  }
}

${(p) => p.theme.breakpoints.down("md")} {

  &.scroll-down {
    .scroll-to-top {
      bottom: 20px;
      transition: bottom 0.4s linear 0.2s;
    }
  }
  .scroll-to-top {
    bottom: 70px;
    transition: bottom 0.4s linear 0.2s;
  }
}
}

`;

export default GlobalStyle;
