//TODO - Delete these entries move these into the CMS
export const tillitAboutUsPath = `/about`;
export const tillitInTheNews = "/tillit-in-the-news/";
export const tillitInvestmentCommitteePath = `/investment-committee`;
export const tillitManifestoPath = `${tillitInvestmentCommitteePath}#our-manifesto`;
export const tillitContactPath = `/contact`;
export const tillitInsightsPath = `/insights`;
export const tillitFeesPath = `/fees`;
export const tillitJargonPath = `/jargon-flashcards`;
export const tillitCulturePath = `/our-culture`;
export const tillitOpenPositionsPath = `/our-culture#positions`;
export const tillitFAQs = `/faqs`;
export const tillitISAProductPagePath = `/stocks-shares-isa`;
export const tillitGIAProductPagePath = `/general-investment-account`;
export const tillitSIPPProductPagePath = `/pension`;
export const transfersWebPage = `/transfer-concierge`;
export const termsAndConditions = `/terms-and-conditions`;
export const privacyPolicy = `/privacy`;
export const complaintsPolicy = `/complaints-policy`;
export const tillitDarkUniversePath = `/the-dark-universe`;
