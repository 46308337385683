import React from "react";
import { Link } from "gatsby";
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaYoutubeSquare } from "react-icons/fa";
import { TillitLogo } from "../../design-system/TillitLogo/TillitLogo";
import {
  ContentContainer,
  CopyBlock,
  CopyRightsContainer,
  LogoContainer,
  Menu1,
  Menu2,
  NewsLetterSignUpContainer,
  PageFooter,
  SocialMenu,
} from "./Footer.styles";
import * as paths from "../../../paths";
import { trackGa, GaEventNames } from "../../../helpers/track";
import { isLinkSameDomain } from "../../../helpers/isLinkSameDomain";
import { Container, Padding } from "../../design-system/Container/Container";
import { Widget } from "@typeform/embed-react";

export interface MenusProps {
  url: string;
  label: string;
  icon?: JSX.Element;
  className?: string;
  target?: "_blank" | "_self";
  outbound?: boolean;
}

export interface PureFooterProps {
  menu1: Array<MenusProps>;
  menu2: Array<MenusProps>;
  socialMenu: Array<MenusProps>;
  onClick: (label: string, url: string, outbound?: boolean) => void;
}

export function PureFooter({
  menu1,
  menu2,
  socialMenu,
  onClick,
}: PureFooterProps) {
  return (
    <PageFooter>
      <Container padding={Padding.none}>
        <ContentContainer>
          <LogoContainer>
            <TillitLogo color="#ffffff" size={1.5} />
          </LogoContainer>
          <Menu1>
            {menu1 &&
              menu1.map((item) => {
                const isGatsbyLink = isLinkSameDomain(item.url);
                return (
                  <li key={item.url} className={item.className}>
                    {isGatsbyLink ? (
                      <Link
                        to={item.url}
                        onClick={(ev) =>
                          onClick(item.label, item.url, item.outbound)
                        }
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <a
                        href={item.url}
                        onClick={(ev) =>
                          onClick(item.label, item.url, item.outbound)
                        }
                        rel="noreferrer"
                      >
                        {item.label}
                      </a>
                    )}
                  </li>
                );
              })}
          </Menu1>
          <Menu2>
            {menu2 &&
              menu2.map((item) => {
                const isGatsbyLink = isLinkSameDomain(item.url);
                return (
                  <li key={item.url} className={item.className}>
                    {isGatsbyLink ? (
                      <Link
                        to={item.url}
                        onClick={(ev) =>
                          onClick(item.label, item.url, item.outbound)
                        }
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <a
                        href={item.url}
                        onClick={(ev) =>
                          onClick(item.label, item.url, item.outbound)
                        }
                        rel="noreferrer"
                      >
                        {item.label}
                      </a>
                    )}
                  </li>
                );
              })}
          </Menu2>
          <NewsLetterSignUpContainer>
            <Widget
              id="H0hj0ggH"
              style={{
                width: "100%",
                height: "300px",
              }}
              inlineOnMobile
            />
          </NewsLetterSignUpContainer>
          <SocialMenu>
            {socialMenu &&
              socialMenu.map((item) => (
                <li key={item.url} className={item.className}>
                  <a
                    href={item.url}
                    onClick={() => onClick(item.label, item.url, item.outbound)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.icon && item.icon} {item.label}
                  </a>
                </li>
              ))}
          </SocialMenu>
        </ContentContainer>
        <CopyRightsContainer>
          <CopyBlock darkTheme={true}>
            &copy; {new Date().getFullYear()} TILLIT Limited
          </CopyBlock>
          <CopyBlock darkTheme={true}>
            TILLIT is the trading name of TILLIT Limited and is registered in
            England & Wales with company number 12357713. Its registered office
            address is 42-46 Princelet Street, London, E1 5LP. TILLIT Limited is
            authorised and regulated by the Financial Conduct Authority (FRN
            983417).
          </CopyBlock>
          <CopyBlock darkTheme={true}>
            Our website offers information about investing and saving, but not
            personal advice. The value of investments can go up and down, so you
            could get back less than you put in. If you’re not sure which
            investments are right for you, please seek advice from a qualified
            financial adviser.
          </CopyBlock>
        </CopyRightsContainer>
      </Container>
    </PageFooter>
  );
}

export function Footer() {
  const menu1 = [
    {
      url: paths.transfersWebPage,
      label: "Transfer Concierge",
    },
    {
      url: paths.tillitAboutUsPath,
      label: "About us",
    },
    {
      label: "TILLIT in the news",
      url: paths.tillitInTheNews,
    },
    {
      url: paths.tillitFeesPath,
      label: "Fees",
    },
    {
      url: paths.tillitAppHome,
      label: "The Universe",
    },
    {
      url: paths.tillitInvestmentCommitteePath,
      label: "Investment Committee",
    },
    {
      url: paths.tillitInsightsPath,
      label: "The insights",
    },
    {
      url: paths.tillitJargonPath,
      label: "Jargon flashcards",
    },
    {
      url: paths.tillitFAQs,
      label: "FAQs",
    },
    {
      url: paths.tillitContactPath,
      label: "Contact us",
    },
  ];

  const menu2 = [
    {
      url: paths.termsAndConditions,
      label: "Terms and conditions",
    },
    {
      url: paths.privacyPolicy,
      label: "Privacy policy",
    },
    {
      url: paths.complaintsPolicy,
      label: "Complaints policy",
    },
    {
      url: paths.feedbackForm,
      label: "Give us feedback",
      className: "highlighted",
    },
  ];

  const menuSocial = [
    {
      url: paths.linkedInPath,
      label: "LinkedIn",
      icon: <AiFillLinkedin className="icon" />,
      outbound: true,
    },
    {
      url: paths.twitterPath,
      label: "Twitter",
      icon: <AiOutlineTwitter className="icon" />,
      outbound: true,
    },
    {
      url: paths.instagramPath,
      label: "Instagram",
      icon: <AiOutlineInstagram className="icon" />,
      outbound: true,
    },
    {
      url: paths.youTubePath,
      label: "YouTube",
      icon: <FaYoutubeSquare className="icon" />,
      outbound: true,
    },
  ];

  const handleClick = (label: string, url: string, outbound?: boolean) => {
    trackGa({
      event: GaEventNames.click,
      outbound: outbound ? "true" : "false",
      link_url: url,
      link_text: label,
    });
  };

  return (
    <PureFooter
      menu1={menu1}
      menu2={menu2}
      socialMenu={menuSocial}
      onClick={handleClick}
    />
  );
}
